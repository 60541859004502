<template>
  <div class="invest-city-card">
    <h3 class="title">{{ city.name }}</h3>
    <div class="data">
      <div class="performance">
        <div class="number">{{ performance }}</div>
        <div class="desc">Rendement brut moyen</div>
      </div>
      <div class="price">
        <div class="number">{{ price }}</div>
        <div class="desc">Prix moyen au m2</div>
      </div>
    </div>
    <NuxtLink
      :to="`/ou-investir/villes/${city.name}/${city.postalCode}`"
      no-prefetch
      @click="handleClick(city.name)"
    >
      <uikit-button type="link">En savoir plus</uikit-button>
    </NuxtLink>
  </div>
</template>

<script setup>
import { UikitButton } from '@hz/ui-kit'
import { formatNumber } from '../../helpers/utils'

const props = defineProps({
  city: {
    type: Object,
    default: () => ({}),
  },
})

const price = computed(() => {
  if (!props.city.price) {
    return '-'
  }

  const price = Math.round(props.city.price)
  return formatNumber(price, 3, '€')
})

const performance = computed(() => {
  if (!props.city.performance) {
    return '-'
  }

  const performance = Math.round((props.city.performance + Number.EPSILON) * 10) / 10
  return formatNumber(performance, performance.toString().length, '%')
})

const handleClick = (destination) => useTracking().track('[HP] Clic CTA', { destination })
</script>

<style lang="less" scoped>
.invest-city-card {
  background: var(--ds-color-primary-25);
  border: 2px solid var(--ds-color-primary-100);
  border-radius: 12px;
  padding: 32px 40px;
  height: 284px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: var(--ds-font-xl);
    font-weight: var(--ds-weight-semi-bold);
    margin: 0;
  }

  .data {
    display: flex;
    gap: 24px;

    .number {
      color: var(--ds-color-primary-100);
      font-size: 24px;
      min-width: 85px;
    }

    .desc {
      opacity: 65%;
    }
  }
}
</style>
